<i18n locale="th" lang="yaml" >
chart.name : "จำนวน Ticket แบ่งตาม Lot (ตามวันที่เกิดเหตุ ย้อนหลัง 1 ปี) "
chart.summary : "มีจำนวน Ticket ทั้งหมด {total} @:common.ticket.unit"
</i18n>

<template>
	<ChartContainer
		ref="container"
		:loading="loading"
		:is-empty="total <= 0"
		:error-message="error"
		:summary="summary"
		v-bind="$attrs">
		<LineChart ref="chart" />
		<span slot="title">
			{{$t('chart.name')}} <br/>
			<b>{{modelName}}</b>
		</span>
	</ChartContainer>
</template>

<script>
import axios from "axios"
import ChartContainer from "./general/ChartContainer.vue"
import LineChart from "./general/LineChart.vue"
import _randomColor from "randomcolor"
import ApiError from '@utils/errors/ApiError'

export default {
	components : {
		ChartContainer , LineChart
	} ,
	props : {
		modelName : {
			type : String,
			default : undefined
		} ,
		modelId : {
			type : Number,
			default : 0,
		}
	} ,
	data() {
		return {
			total : 0,
			loading : false,
			error : undefined,
		}
	} ,
	computed : {
		summary() {
			if(this.total <=0)
				return undefined;
			else
				return this.$t('chart.summary',{total : this.total})
		}
	} ,
	watch :{
		modelId() {
			this.fetchData()
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		reset() {
			this.total = 0
			this.error = null
			this.loading = false
		} ,
		convertDataset(dataset,monthLabels) {
			if (!this.$notEmpty(dataset))
				return null
			const labels = Object.values(monthLabels)
			const chartDatasets = [];
			const arrayDataset = Object.values(dataset)

			arrayDataset.forEach((data) => {
				if (data.total <= 0)
					return;

				const color = _randomColor({
					hue : 'random' ,
					luminosity : 'light',
					seed : data.lot_id+" :: "+data.lot_name
				});
				const ticketCounts = Object.values(data.months);
				chartDatasets.push({
					label : data.lot_name,
					backgroundColor : color,
					borderColor : color,
					data : ticketCounts,
					fill : false,
				})
			});

			return {
				labels ,
				datasets : chartDatasets
			}
		} ,
		fetchData() {
			this.reset()
			if (this.modelId <= 0) {
				this.$refs.chart.applyChartData()
				return;
			}
			const startDate = this.$dayjs().subtract(12,'month').format("YYYY-MM-DD");
			const endDate= this.$dayjs().format("YYYY-MM-DD");

			this.loading = true
			this.error = undefined
			axios.get("/api/tickets/stat-tickets-by-models/"+this.modelId,{params : {start_date:startDate,end_date:endDate}}).then((response) => {
				this.total = response.data.data.total;
				const chartDataset = this.convertDataset(response.data.data.dataset,response.data.data.month_labels)
				const chartOptions = {
					scales : {
						yAxes : [
							{ticks:{min : 0,precision : 0,suggestedMax:response.data.data.max_value+3}}
						]
					}
				}
				this.$refs.chart.applyChartData(chartDataset,chartOptions)
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			}).finally(() => {
				this.loading = false
			});
		}
	}
}
</script>
